import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import PrimaryButton from '../../../shared/PrimaryButton'
import styles from './index.module.scss'
import Img from '../../../shared/Img'
import { useLeadId } from '../../../../common/lead-id'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../../common/utils'

const RequestEvaluation = ({
  buttonLink,
  buttonText,
  options,
  image,
  title,
}) => {
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )

  return (
    <div
      className="mb-6 grid grid-cols-1 grid-rows-2 border border-solid border-gray-190 text-left md:grid-cols-6 md:grid-rows-1"
      data-testid="request-evaluation"
      id="resolve-debt-consolidation-state-card"
    >
      <div className="md:col-span-2">
        <Img
          objectFit="cover"
          src={image.fields?.file?.url.replace(/^\/\//, 'https://')}
          objectPosition="center 60%"
          layout="fill"
          className={`fixed ${styles.heroImg}`}
          alt="request-evaluation-img"
          styles={{ position: 'relative' }}
        />
      </div>
      <div
        className="col-span-4 mb-4 md:my-4 md:px-6 "
        data-testid="right-side"
      >
        <p className="px-4 text-2xl" data-testid="title">
          {title}
        </p>
        <ul className="list-disc" data-testid="list">
          {options.map((item, index) => (
            <li
              data-testid="list-item"
              key={`list-item-${index}`}
              className="ml-8 py-1 text-sm font-normal text-gray-510"
            >
              {item}
            </li>
          ))}
        </ul>
        <div
          className="mt-8 flex justify-center"
          data-testid="button-container"
        >
          <PrimaryButton
            sectionTitle={title}
            title={buttonText}
            href={`${buttonLink}?${query}`}
            className="!important bg-blue-300 px-5 py-3 text-center text-lg leading-snug tracking-normal text-black-base"
            type="noncta"
          />
        </div>
      </div>
    </div>
  )
}

RequestEvaluation.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}
export default RequestEvaluation
