import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styles from './index.module.scss'
// eslint-disable-next-line import/no-cycle
import { renderOptions } from './constants/renderOptions'

const RichText = ({ contentData, variants, className, style }) => {
  const classNameString = useMemo(
    () =>
      variants.reduce(
        (acc, elem) => `${acc} ${styles[elem]}`,
        `${styles.richText} ${className}`
      ),
    [variants, className]
  )

  const content = useMemo(
    () =>
      contentData?.content
        ? documentToReactComponents(contentData, renderOptions)
        : '',
    [contentData]
  )

  return (
    <div data-testid="richText" className={classNameString} style={style}>
      {content}
    </div>
  )
}

RichText.defaultProps = {
  variants: ['defaultTable'],
  className: '',
  contentData: {},
  style: null,
}

RichText.propTypes = {
  contentData: PropTypes.object,
  variants: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default React.memo(RichText)
