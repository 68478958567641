import { CONTENT_TYPES } from '../../../../common/constants'
// import Table from '../../Table'
// import InfoLinkItems from '../../ContentTemplate/components/InfoLink/InfoLinkItem'
import ProsAndCons from '../../ProsAndCons'
import RequestEvaluation from '../../../pages/DebtConsolidationState/RequestEvaluation'
import FloatedImageText from '../../FloatedImageText'
import { Gallery, IFrames } from '../components/embeddedEntryComponents'
import dynamic from 'next/dynamic'

const Table = dynamic(() => import('../../Table'))

export const embeddedEntryComponentsMap = {
  [CONTENT_TYPES.DEBT_CONSOLIDATION_CARDS]: RequestEvaluation,
  //[CONTENT_TYPES.TABLE_OF_CONTENT]: TableOfContent,
  //[CONTENT_TYPES.SECTION_TITLES]: Heading,
  //[CONTENT_TYPES.INFO_LINKS]: InfoLinkItem,
  [CONTENT_TYPES.IFRAMES]: IFrames,
  [CONTENT_TYPES.GALLERY]: Gallery,
  [CONTENT_TYPES.FLOATED_IMAGE_TEXT]: FloatedImageText,
  // [CONTENT_TYPES.DEBT_CONSOLIDATION_CARDS]: RequestEvaluation,

  [CONTENT_TYPES.TABLES]: Table,
  [CONTENT_TYPES.NEW_PROS_AND_CONS]: ProsAndCons,
  // [CONTENT_TYPES.INFO_LINKS]: InfoLinkItem,
}
