import PropTypes from 'prop-types'
import React from 'react'

const H3 = ({ text, id, className }) => (
  <h3 className={`${className}`} id={id}>
    {text}
  </h3>
)

H3.defaultProps = {
  className: 'font-normal heading-3 my-primary',
}

H3.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
}

export default React.memo(H3)
