import PropTypes from 'prop-types'
import React from 'react'

const H2 = ({ text, id, className }) => (
  <h2 className={` mb-primary-md ${className}`} id={id}>
    {text}
  </h2>
)

H2.defaultProps = {
  className:
    'text-black-base font-normal heading-2 margin-b-primary margin-t-primary-lg',
}

H2.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default H2
