// eslint-disable-next-line import/no-cycle
import { embeddedEntryComponentsMap } from '../constants/embeddedEntryComponentsMap'
import Img from '../../Img'

export const EmbeddedAsset = (node) => {
  const source = node?.data?.target?.fields

  if (source?.file?.contentType.includes('image')) {
    return (
      <div className="mx-auto my-8 text-center" data-testid="embedded-asset">
        <Img
          src={source?.file?.url.replace(/^\/\//, 'https://')}
          layout="intrinsic"
          height={source.file.details.image.height}
          width={source.file.details.image.width}
          alt={source?.title}
          styles={{ position: 'relative' }}
        />
        {!!source?.description && (
          <figcaption className="mt-8 text-center text-ms text-gray-240">
            <em>{source.description}</em>
          </figcaption>
        )}
      </div>
    )
  }

  return null
}

export const EmbeddedEntry = (node) => {
  const { fields, sys } = node.data.target
  const { id } = sys.contentType.sys

  const Component = embeddedEntryComponentsMap[id]
  if (Component) {
    return <Component {...fields} />
  }

  return <div />
}
