import React from 'react'
import PropTypes from 'prop-types'
import RelatedPostsItem from './RelatedPostsItem'
import H3 from '../../../Headings/H3'
import { beautifyUrlPath } from '../../../../../common/utils'

const RelatedPosts = ({ items }) => (
  <div className="w-content mx-auto my-6 border-y">
    <div className="padding-b-primary lg:px-0">
      <H3
        className="margin-t-primary pb-4 text-base font-medium md:text-4xl lg:text-base"
        text="You May Like These"
        id="You May Like These"
      />
      <div className="gap-primary grid grid-cols-4 gap-y-6 lg:grid-cols-8 lg:gap-y-10">
        {items.map((item, i) => (
          <RelatedPostsItem
            key={i}
            img={item.image}
            url={beautifyUrlPath(item.path)}
            text={item.title}
          />
        ))}
      </div>
    </div>
  </div>
)

RelatedPosts.propTypes = {
  items: PropTypes.array.isRequired,
}

export default RelatedPosts
