import React from 'react'
import PropTypes from 'prop-types'
import { prosStyle, consStyle } from './index.module.scss'

const ProsAndCons = ({
  title,
  prosTitle,
  consTitle,
  prosContent,
  consContent,
}) => (
  <section className="py-5">
    {title ? (
      <h2 className="mb-primary-md text-2xl lg:text-primary-xs">{title}</h2>
    ) : null}
    <span className="lg:text-2xl">{prosTitle}</span>
    <ul className="mt-primary-md pl-7 lg:pl-12">
      {prosContent.split('\n').map((text, key) => (
        <li key={`pros-${key}`} className={`text-lg ${prosStyle}`}>
          <div className="lg:pl-5">{text}</div>
        </li>
      ))}
    </ul>
    <hr className="my-primary-md" />
    <span className="lg:text-2xl">{consTitle}</span>
    <ul className="mt-primary-md pl-7 lg:pl-12">
      {consContent.split('\n').map((text, key) => (
        <li key={`cons-${key}`} className={`text-lg lg:my-5 ${consStyle}`}>
          <div className="lg:pl-5">{text}</div>
        </li>
      ))}
    </ul>
  </section>
)

ProsAndCons.defaultProps = {
  prosTitle: 'Pros',
  consTitle: 'Cons',
}
ProsAndCons.propTypes = {
  title: PropTypes.string.isRequired,
  prosTitle: PropTypes.string,
  consTitle: PropTypes.string,
  prosContent: PropTypes.string.isRequired,
  consContent: PropTypes.string.isRequired,
}

export default React.memo(ProsAndCons)
