import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import getConfig from 'next/config'

const computeSeoProps = (props, IS_LOWER_ENV) => {
  const nofollow = IS_LOWER_ENV ? true : props.nofollow || false
  const noindex = IS_LOWER_ENV ? true : props.noindex || false

  return {
    ...props,
    nofollow,
    noindex,
  }
}

const WithSeoOverrides = (props) => {
  const { IS_LOWER_ENV } = getConfig()?.publicRuntimeConfig
  // Use useMemo to recalculate SEO props only when necessary
  const seoProps = useMemo(
    () => computeSeoProps(props, IS_LOWER_ENV),
    [props, IS_LOWER_ENV]
  )

  return <NextSeo {...seoProps} />
}

export default WithSeoOverrides
