import PropTypes from 'prop-types'
import React from 'react'

const H4 = ({ text, id, className }) => (
  <h4 className={`margin-y-primary ${className}`} id={id}>
    {text}
  </h4>
)

H4.defaultProps = {
  className: 'text-black-700',
}

H4.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
}

export default H4
