/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import VideoPlayer from '../VideoPlayer'

const Iframe = ({
  title,
  src,
  className,
  frameborder,
  allowfullscreen,
  ...props
}) => {
  const iframeRef = useRef()
  const [height, setHeight] = useState()

  const handleReceivedMessage = useCallback(
    (event) => {
      const { data } = event

      if (
        !iframeRef.current ||
        event.source !== iframeRef.current.contentWindow
      ) {
        return
      }

      let receivedHeight

      if (data?.height) {
        receivedHeight = Number(data.height)
      }

      if (typeof data === 'string') {
        try {
          const jsonData = JSON.parse(data)

          if (jsonData) {
            if (
              jsonData?.type?.toLowerCase() === 'measure' &&
              jsonData?.details
            ) {
              receivedHeight = Number(jsonData.details.height)
            }
          }
        } catch (e) {
          if (data.startsWith('height')) {
            const h = data.split(':')[1]
            if (h) {
              receivedHeight = Number(h) + 105
            }
          }
        }
      }

      if (!Number.isNaN(receivedHeight)) {
        setHeight(`${receivedHeight}px`)
      }
    },
    [setHeight, iframeRef]
  )

  useEffect(() => {
    window.addEventListener('message', handleReceivedMessage, false)

    return () => window.removeEventListener('message', handleReceivedMessage)
  }, [handleReceivedMessage])

  const type = useMemo(() => {
    const srcUrl = new URL(src.replace(/^\/\//, 'https'))

    switch (srcUrl.hostname.replace(/^www./, '')) {
      case 'youtube.com':
      case 'youtu.be':
        return 'youtube'
      case 'instagram.com':
        return 'instagram'
      default:
        return 'default'
    }
  }, [src])

  const classNames = useMemo(() => {
    const classes = {
      root: '',
      frame: 'w-full',
    }

    switch (type) {
      case 'instagram':
        classes.frame += ' border rounded'
        break
      default:
        break
    }

    if (className) {
      classes.frame += ` ${className} `
    }

    return classes
  }, [type, className])

  if (type === 'youtube') {
    return (
      <div className="h-48 sm:h-80 md:h-96" data-testid="video-container">
        <VideoPlayer url={src} />
      </div>
    )
  }

  return (
    <div className={classNames.root} data-testid="iFrames">
      <iframe
        ref={iframeRef}
        {...props}
        title={title}
        src={src}
        className={`${classNames.frame}`}
        data-class={`${classNames.frame}`}
        width="100%"
        height={height}
        style={{
          height,
        }}
      />
    </div>
  )
}

Iframe.defaultProps = {
  allowfullscreen: '',
  frameborder: 'no',
  title: 'Iframe',
  className: '',
}

Iframe.propTypes = {
  className: PropTypes.string,
  allowfullscreen: PropTypes.any,
  frameborder: PropTypes.any,
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default Iframe
