import React from 'react'
import styles from './index.module.scss'
import RichText from '../RichText'
import Img from '../Img'
import classNames from 'classnames'

const FloatedImageText = (fields) => {
  const imageSrc = fields.image.fields.file.url
  const { width, height } = fields.image.fields.file.details.image
  const { imageLink, imageDirection, content } = fields
  const { title } = fields.image.fields

  return (
    <div data-testid="floated-image-text" className="mt-8 overflow-hidden">
      <div
        className={classNames(styles.imageContainer, styles[imageDirection])}
      >
        {imageLink ? (
          <a href={imageLink}>
            <Img
              src={imageSrc}
              alt={title}
              width={width}
              height={height}
              layout="intrinsic"
              dataTestId="image"
              styles={{
                position: 'relative',
                height: `${height}px`,
                width: `${width}px`,
              }}
            />
          </a>
        ) : (
          <Img
            src={imageSrc}
            alt={title}
            width={width}
            height={height}
            layout="intrinsic"
            dataTestId="image"
            styles={{
              position: 'relative',
              height: `${height}px`,
              width: `${width}px`,
            }}
          />
        )}
      </div>
      <RichText contentData={content} className={styles.textContainer} />
    </div>
  )
}

export default FloatedImageText
