import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useAnalytics } from '../../../common/analytics'
import classNames from 'classnames'
import NextLink from 'next/link'

const PrimaryButton = ({
  className,
  title,
  href,
  sectionTitle,
  rel,
  target,
  onClick,
  dataTestId,
  id,
  sticky,
  heroBtn,
  isTrack,
  bold,
}) => {
  const { track } = useAnalytics()

  const handleClick = useCallback(() => {
    const data = {
      nav_link_section: heroBtn ? 'Hero Card' : 'Page',
      page_section: sectionTitle,
      click_type: sticky ? 'Sticky Button Click' : 'Button Click',
      click_id: title,
      click_url: href,
      click_text: `FDR-Web | ${title}`,
      track_event: 'button_click',
    }

    if (isTrack) {
      track(data, {}, 'click')
    }

    if (typeof onClick === 'function') {
      onClick()
    }
  }, [title, href, track, onClick, sectionTitle, sticky, heroBtn, isTrack])

  return href ? (
    <NextLink
      id={id ? `link-${id}` : `primary-button-${title.replaceAll(' ', '-')}`}
      href={href}
      rel={rel}
      prefetch={false}
      data-testid={dataTestId}
      target={target}
      className={`cursor-pointer rounded-lg text-lg ${className}`}
      onClick={handleClick}
    >
      {bold ? <b>{title}</b> : title}
    </NextLink>
  ) : (
    <button
      className={classNames('cursor-pointer rounded-lg text-lg', className)}
      onClick={handleClick}
      id={
        id
          ? `primary-button-${id}`
          : `primary-button-${title.replaceAll(' ', '-')}`
      }
    >
      {bold ? <b>{title}</b> : title}
    </button>
  )
}

PrimaryButton.defaultProps = {
  target: '_self',
  className: 'bg-blue-base text-white py-2.5 px-9',
  href: '',
  rel: null,
  pageLocation: '',
  type: 'cta',
  dataTestId: 'primary-button',
  sectionTitle: '',
  onClick: undefined,
  sticky: false,
  isTrack: true,
  heroBtn: false,
  bold: true,
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  target: PropTypes.string,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  sectionTitle: PropTypes.string,
  sticky: PropTypes.bool,
  heroBtn: PropTypes.bool,
  isLeadCta: PropTypes.bool,
  href: PropTypes.string,
  isTrack: PropTypes.bool,
  bold: PropTypes.bool,
}

export default PrimaryButton
