import React from 'react'
import Img from '../../Img'
import PropTypes from 'prop-types'

export default function PlayCircleOutlined({ className }) {
  return (
    <div data-testid="play-btn" className={`absolute ${className}`}>
      <Img
        src="/next-assets/icons/video-play-btn.svg"
        alt="play-circle-outLine"
        layout="responsive"
        className="h-full w-full"
        dataTestId="play-circle-icon"
        styles={{ position: 'relative' }}
      />
    </div>
  )
}

PlayCircleOutlined.defaultProps = {
  className: 'pl-4 pb-2 h-16 w-16 md:h-24 md:w-24',
}

PlayCircleOutlined.propTypes = {
  className: PropTypes.string,
}
