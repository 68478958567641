import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../Img'
// eslint-disable-next-line import/no-cycle
import Iframe from '../../Iframe'
import { getAttributesFromHtml } from '../../../../common/helpers'
import LazyLoad from 'react-lazyload'

export const Gallery = ({ images }) => (
  <div className="my-5 grid grid-cols-3 gap-2" data-testid="gallery">
    {images.map((item, i) => (
      <Img
        key={`image_${i}`}
        src={item.fields.file.url.replace(/^\/\//, 'https://')}
        layout="intrinsic"
        height={item.fields.file.details.image.height}
        width={item.fields.file.details.image.width}
        className="object-cover"
        alt={item.fields.file.title}
      />
    ))}
  </div>
)

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
}

export const IFrames = ({ content }) => {
  const attributes = getAttributesFromHtml(content)
  const { class: className, ...restAttributes } = attributes

  return (
    <LazyLoad>
      <div className="my-5" data-testid="iFrame">
        <Iframe className={className} {...restAttributes} />
      </div>
    </LazyLoad>
  )
}

IFrames.propTypes = {
  content: PropTypes.string.isRequired,
}
